@font-face {
  font-family: "Material Icons Round";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/MaterialIconsRound.woff2") format("woff2");
}

@font-face {
  font-family: "Material Icons outlined";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/font/MaterialIconsOutlined.woff2") format("woff2");
}

.material-icons {
  cursor: pointer;
  font-weight: normal;
  font-style: normal;
  // font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: block; // inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;

  &.round {
    font-family: 'Material Icons Round';
  }

  &.outlined {
    font-family: 'Material Icons Outlined';
  }
}

.btnDefaultIcon {
  font-size: 18px;
}

.btnSmallIcon {
  font-size: 14px;
}

.btnLargeIcon {
  font-size: 24px;
}
