@tailwind base;
@tailwind components;
@tailwind utilities;


@import './styles/icon.less';

@pj-scrollbar-width: 6px;
@pj-scrollbar-height: 6px;

@pj-scrollbar-width-hover: 6px;
@pj-scrollbar-height-hover: 6px;

@pj-scrollbar-height-radius: 6px;
@pj-scrollbar-height-tracker: #ebeef2;
@pj-scrollbar-height-thumb: #dadfe5;
@pj-scrollbar-height-thumb-hover: #bcc2cc;
@pj-scrollbar-height-thumb-active: #9299a6;

::-webkit-scrollbar {
  width: @pj-scrollbar-width;
  height: @pj-scrollbar-height;
}

*:hover::-webkit-scrollbar {
  width: @pj-scrollbar-width-hover;
  height: @pj-scrollbar-width-hover;
}

::-webkit-scrollbar-track {
  border-radius: @pj-scrollbar-height-radius;
  background-color: @pj-scrollbar-height-tracker;
}

::-webkit-scrollbar-thumb {
  border-radius: @pj-scrollbar-height-radius;
  background-color: @pj-scrollbar-height-thumb;

  &:hover {
    background-color: @pj-scrollbar-height-thumb-hover;
  }

  &:active {
    background-color: @pj-scrollbar-height-thumb-active;
  }
}



// @layer base {
//   img {
//     display: inline;
//   }
// }

img {
  display: inline;
}
